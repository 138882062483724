.timeline-element {
    position: relative;
    margin: 2em 0;
}

    .timeline-element > div {
        min-height: 1px;
    }

    .timeline-element:after {
        content: "";
        display: table;
        clear: both;
    }

    .timeline-element:first-child {
        margin-top: 0;
    }

    .timeline-element:last-child {
        margin-bottom: 0;
    }

@media only screen and (min-width: 1170px) {
    .timeline-element {
        margin: 0;
    }

        .timeline-element:first-child {
            margin-top: 0;
        }

        .timeline-element:last-child {
            margin-bottom: 0;
        }
}

.timeline-element-icon {
    position: absolute;
    top: 0;
    left: 0;
    width: 25px;
    /*height: 20px;
    border-radius: 50%;
    box-shadow: 0 0 0 4px white, inset 0 2px 0 rgba(0, 0, 0, 0.08), 0 3px 0 4px rgba(0, 0, 0, 0.05);*/
}

    .timeline-element-icon svg {
        display: block;
        width: 12px;
        height: 12px;
        position: relative;
        left: 50%;
        top: 50%;
        margin-left: -12px;
        margin-top: -12px;
    }

@media only screen and (min-width: 1170px) {
    .timeline--two-columns .timeline-element-icon {
        width: 25px;
        /*height: 20px;*/
        left: 50%;
        margin-left: -10px;
    }
}

.timeline-element-content {
    position: relative;
    margin-left: 60px;
    background: white;
    border-radius: 0.25em;
    box-shadow: 0 0px 0 #ddd;
}

.timeline-element--no-children .timeline-element-content {
    background: transparent;
    box-shadow: none;
}

.timeline-element-content:after {
    content: "";
    display: table;
    clear: both;
}

.timeline-element-content h2 {
    color: #303e49;
}

.timeline-element-content p, .timeline-element-content .timeline-element-date {
    font-size: 0.65rem;
    font-weight: 500;
    color: #333;
}


.timeline-element-content p {
    margin: 1em 0 0;
    line-height: 1.6;
}

.timeline-element-title {
    margin: 0;
}

.timeline-element-subtitle {
    margin: 0;
}

.timeline-element-content .timeline-element-date {
    display: inline-block;
    float: left;
    padding: .1em 0;
    opacity: .7;
}

.timeline-element-content::before {
    content: '';
    position: absolute;
    top: 16px;
    right: 100%;
    height: 0;
    width: 0;
    border: 7px solid transparent;
    border-right: 7px solid white;
}

.timeline-element--no-children .timeline-element-content::before {
    display: none;
}

@media only screen and (min-width: 768px) {
    .timeline-element-content h2 {
        font-size: 20px;
        font-size: 1.25rem;
    }

    .timeline-element-content p {
        font-size: 16px;
        font-size: 1rem;
    }

    .timeline-element-content .timeline-element-date {
        font-size: 14px;
        font-size: 0.65rem;
    }
}

@media only screen and (min-width: 1170px) {
    .timeline--two-columns .timeline-element-content {
        margin-left: 0;
        padding: 1.5em;
        width: 44%;
    }

        .timeline--two-columns .timeline-element-content::before {
            top: 24px;
            left: 100%;
            border-color: transparent;
            border-left-color: white;
        }

        .timeline--two-columns .timeline-element-content .timeline-element-date {
            position: absolute;
            width: 100%;
            left: 124%;
            /*top: 6px;
            font-size: 16px;
            font-size: 1rem;*/
        }

    .timeline--two-columns .timeline-element:nth-child(even):not(.timeline-element--left) .timeline-element-content,
    .timeline--two-columns .timeline-element.timeline-element--right .timeline-element-content {
        float: right;
    }

        .timeline--two-columns .timeline-element:nth-child(even):not(.timeline-element--left) .timeline-element-content::before,
        .timeline--two-columns .timeline-element.timeline-element--right .timeline-element-content::before {
            top: 24px;
            left: auto;
            right: 100%;
            border-color: transparent;
            border-right-color: white;
        }

        .timeline--two-columns .timeline-element:nth-child(even):not(.timeline-element--left) .timeline-element-content .timeline-element-date,
        .timeline--two-columns .timeline-element.timeline-element--right .timeline-element-content .timeline-element-date {
            left: auto;
            right: 124%;
            text-align: right;
        }
}
