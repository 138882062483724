
/* isMobile */
@media (max-width: 991px) {
    .card {
        font-size: 15px;
    }

    .react-bootstrap-table, .popover {
        font-size: 13px;
    }

    .css-1wa3eu0-placeholder {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 85%;
    }

    .fitColumnContent .col {
        min-width: fit-content;
    }


    /*#region Date Input*/
    .DateRangePicker {
        width: 100%;
    }

    .DateRangePickerInput {
        width: 100%;
        display: flex !important;
        align-items: center;
        
    }

    .DateRangePickerInput .DateInput {
        width: 100% !important;
    }

    .DateRangePickerInput_arrow {
        padding: 0 2%;
    }


    .SingleDatePicker {
        width: 100%;
    }

    .SingleDatePickerInput {
        width: 100%;
    }

    .SingleDatePickerInput .DateInput {
        width: 80% !important;
    }
    /*#endregion*/


    /*#region Tabs*/
    .nav {
        flex-wrap: nowrap !important;
        overflow-x: auto;
        overflow-y: hidden;
    }

    .nav::-webkit-scrollbar {
        display: none;
    }

    .nav-link {
        display: flex;
        white-space: nowrap;
        align-items: center;
    }
    /*#endregion*/


    /*#region Timeline*/
    .timeline-element-content p, .timeline-element-content .timeline-element-date {
        font-size: 0.75rem !important;
    }

    .timelineDetailsMobile .card-body {
        font-size: 13px !important;
    }
    /*#endregion*/
}