
.react-bootstrap-table-pagin2ation-list-hidden {
    display: none;
}

/*Will edit selected date or the endpoints of a range of dates*/
.CalendarDay__selected {
    background: #165C7D;
    color: white;
}

/* Will edit when hovered over. _span style also has this property*/
    .CalendarDay__selected:hover {
        background: #165C7D;
        color: white;
    }


.SingleDatePickerInput_calendarIcon {
    margin: 0px;
    background-color: #e9ecef;
    height: 29px;
    padding: 7px;
}

.DateInput_input__focused {
    border-bottom: 2px solid #165C7D
}

.SingleDatePickerInput_calendarIcon_svg {
    fill : #495057
}

.SingleDatePicker {
    /*box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;*/
}

.DateRangePicker {
    /* box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;*/
}

.DateRangePickerInput_calendarIcon  {
    margin: 0px;
    height: 32px;
    padding: 6px;
    background-color: white;
}
.SingleDatePickerInput_calendarIcon {
    margin: 0px;
    height: 32px;
    padding: 6px;
    background-color: white;
}

.SingleDatePickerInput__withBorder {
    border-radius: 3px;
    height: 38px;
}
.DateInput_input__small {
    height: 34px;
    border: none !important;
}


.DateRangePickerInput_calendarIcon_svg {
    fill: #495057
}
.DateRangePickerInput__withBorder {
    border-radius: 3px;
    height: 38px;
    border: 2px solid #4F80F611;
    border-radius: 5px !important;
}


.CustomSingleDatePicker .DayPicker_transitionContainer {
    height: 350px !important;
}

.CustomSingleDatePicker .CalendarMonthGrid_month__horizontal {
    margin-bottom: 15px !important;
}

.CustomSingleDatePicker .CalendarMonthGrid {
    height: 100%
}

.SingleDatePickerInput_calendarIcon {
    background-color: transparent !important;
    display: inline-flex;
    align-items: center;
}

.SingleDatePickerInput_clearDate{
    display: flex;
    align-items: center;
    height: 30px;
    justify-content: center;
    width: 30px;
    margin-left: 10px;
}