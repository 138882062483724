.public-DraftStyleDefault-depth0.public-DraftStyleDefault-listLTR{
    margin-left: 2.5em !important;
}

.public-DraftStyleDefault-block{
    margin: 0 !important;
}

.moveIt {
    transition: 200ms;
}

.CalendarMobileNav .DateInput_input.DateInput_input_1.DateInput_input__small.DateInput_input__small_2 {
    text-align: center !important;
    padding: 0.5rem 0;
}

.MobileCalendarBulk .DayPicker_transitionContainer.DayPicker_transitionContainer_1.DayPicker_transitionContainer__vertical.DayPicker_transitionContainer__vertical_2 {
    height: 355px !important;
}

.MobileCalendarBulk .DayPickerNavigation_button__verticalDefault{
    box-shadow: unset !important;
}

.veryCoolButtons {
    aspect-ratio: 1 / 1;
    transition: 500ms;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    font-size: 1.2em;
    border: 1px solid #bbc1c9ab;
    padding: 0.8rem;
    background: white;
    box-shadow: 0px 3px 15px #6f73882c;
    border-radius: 3px;
}

.coolCollapse {
    width: 30px;
    height: 30px;
    border-radius: 25px;
    border: 1px solid #bbc1c9ab;
    font-size: 0.7em;
    position: absolute;
    right: -15px;
    box-shadow: 0px 3px 15px #6f73882c;
    transition: 500ms;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    cursor: pointer;
}

.heyTravelAiButton {
    aspect-ratio: 1 / 1;
    transition: 500ms;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    font-size: 1.2em;
    padding: 0.6rem 0.8rem;
    border-radius: 10px;
}

.heyTravelAiButton:hover {
    background: #a5a5a540;
    border-radius: 10px;
}

.EmailFromEmailRes {
    background: #f9f9f9b8;
    transition: 200ms;
    border-radius: 2px;
    border: 1px solid #f9f9f9b8;
}

.coolTooltip .tooltip-inner {
    background-color: #767679 !important;
    font-size: 0.8em !important;
    border-radius: 3px;
}

.coolTooltip.bs-tooltip-top .arrow, .coolTooltip.bs-tooltip-left .arrow, .coolTooltip.bs-tooltip-right .arrow, .coolTooltip.bs-tooltip-bottom .arrow {
    display: none;
}

.coolTooltip.bs-tooltip-top .arrow::before, .coolTooltip.bs-tooltip-auto[x-placement^="top"] .arrow::before {
    border-top-color: #767679 !important;
}

.EmailFromEmailRes:hover, .EmailFromEmailRes.SelectedEmail {
    box-shadow: 0px 3px 15px #6F73881A !important;
    border-top: 1px solid #40648738;
    border-bottom: 1px solid #40648738;
    background-color: #eaeced;
}

.EmailCloseTab {
    aspect-ratio: 1 / 1;
    border-radius: 100%;
    width: 2rem;
    color: #494b4f;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 300ms;
    visibility: hidden;
}

.activeTemp {
    background-color: #165C7D;
    color: white;
    border: 1px solid white;
    cursor: pointer;
    padding: 0.25rem 1rem;
    border-radius: 5px;
}

.inactiveTemp {
    background-color: white;
    color: #165C7D;
    border: 1px solid #165C7D;
    cursor: pointer;
    padding: 0.25rem 1rem;
    border-radius: 5px;
}

.activeTemp.generate:hover, .activeTemp.generate:focus, .activeTemp.generate:active {
    background-color: #165C7D;
    color: white;
    border: 1px solid white;
}

.GPTactiveTemp{
    background-color: #165C7D;
    color: white;
    border: 1px solid white;
    cursor: pointer;
    padding: 0.25rem 1rem;
    border-radius: 5px;
    font-weight: 300;
}

.GPTinactiveTemp{
    background-color: white;
    color: #165C7D;
    border: 1px solid #165C7D;
    cursor: pointer;
    padding: 0.25rem 1rem;
    border-radius: 5px;
    font-weight: 300;
}

.GPTactiveTemp.generate:hover, .GPTactiveTemp.generate:focus, .GPTactiveTemp.generate:active {
    background-color: #165C7D;
    color: white;
    border: 1px solid white;
}

.disabledTemp {
    background-color: #f3f5f7;
    color: #6f747f;
    border: 1px solid #d1d5db;
    cursor: not-allowed;
    padding: 0.25rem 1rem;
    border-radius: 5px;
    font-weight: 300;
    pointer-events: none;
    opacity: 0.6;
}

.GPTbadge{
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: white;
    z-index: 999;
    border: none;
}

.GPTbadge:focus{
    box-shadow: none !important;
}

.GPTbadge:hover{
    background-color: white;
    border-color: unset !important;
}

#SearchSubMenu{
    min-height: unset !important;
    font-size: 13px;
}

.invertbtn {
    color: #007BFF !important;
    background-color: transparent !important;
    border: transparent !important;
}

.invertbtn:hover {
    color: #0056b3 !important
}

.EmailCloseTab:hover {
    box-shadow: 0px 3px 15px #6f73882c;
    color: #6f747f;
}

.veryCoolButtons:hover, .coolCollapse:hover {
    background: #f3f5f7;
    box-shadow: none;
    color: #656565;
}

.TotalEmailRes:hover {
    background: #f3f3f3;
    border-radius: 2px;
    transition: 200ms;
}

#EmailReservation .DateRangePicker.DateRangePicker_1, #EmailReservation .DateRangePickerInput.DateRangePickerInput_1.DateRangePickerInput__withBorder.DateRangePickerInput__withBorder_2 {
    width: 100%;
}

.EmailSearchLine{
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    background-color: #fbfbfb;
}

.hoverEdit{
    font-size: 0.8em;
    color: lightgrey;
    transition: 200ms;
    cursor: pointer;
}

.FAQsHover:hover{
    background-color: #9e9e9e1e;
}

.hoverEditFAQs{
    font-size: 0.8em;
    color: lightgrey;
    transition: 200ms;
    cursor: pointer;
}

.smallPagination * {
    font-size: 0.9em !important;
}

.hoverEdit:hover, .hoverEdit.selectedEdit{
    color: #007bff;
    box-shadow: 0px 3px 15px #6F73881A !important;
}

.hoverEditFAQs:hover, .hoverEditFAQs.selectedEdit{
    color: #007bff;
}

.EmailSearchLine:hover, .EmailSearchLine.isSelected {
    background-color: #eaeced;
    box-shadow: 0px 3px 15px #6F73881A !important;
}

.CoolDoc {
    box-shadow: 0px 3px 15px #6F73881A;
    color: grey;
    border-radius: 2px;
    border: 1px solid #d1d5db;
    transition: 200ms;
}

.CoolDoc:hover {
    box-shadow: 0px 3px 15px #6f738875;
}

.pagination.react-bootstrap-table-page-btns-ul {
    justify-content: end;
}

@media only screen and (max-width: 1500px) {
    .dropdown-item{
        border-left: unset !important;
    }
    .dropdown-item.active, .dropdown-item:active, .dropdown-item:hover, .dropdown-item:focus {
        background-color: unset !important;
    }
    .dropleft .dropdown-toggle::before{
        transform: rotate(270deg);
    }
}

.coolBadge {
    box-shadow: none;
    font-size: 85%;
    border-radius: 0.25rem;
    font-weight: 500;
    line-height: 1;
    white-space: nowrap;
    padding: 0.5rem;
}

.coolBadge.successBadge {
    background: #28a7451f;
    border: 1px solid #28a745;
    color: #28a745;
}

.coolBadge.dangerBagde {
    background: #28a7451f;
    border: 1px solid #28a745;
    color: #28a745;
}

.coolLabel {
    font-size: 0.9em;
    margin-bottom: 0.25rem;
    margin-top: 0.5rem;
    color: #6c757d !important;
}

input[type=checkbox].custom-control-input{
    right: 0;
}

.SingleDatePicker_picker {
    z-index: 9999 !important;
}

.CalendarMobileNav .SingleDatePicker_picker.SingleDatePicker_picker_1.SingleDatePicker_picker__directionLeft.SingleDatePicker_picker__directionLeft_2 {
    left: -78px !important;
}

.CalendarMobileNav .SingleDatePicker_picker.SingleDatePicker_picker_1.SingleDatePicker_picker__directionLeft.SingleDatePicker_picker__directionLeft_2 {
    z-index: 9999;
}

#cusotmselectcomparepmsdata {
    z-index: 1000;
}

.comparePmsCell {
    z-index: 999 !important;
}

.customSelectContainer .unplesantInput {
    width: unset !important;
}

.bluredText{
    color: transparent;
    text-shadow: 0 0 5px rgba(0,0,0,0.5);
}

.bluredBlueText {
    color: transparent;
    text-shadow: 0 0 5px rgba(65, 116, 248,0.5);
}

.cursor-pointer{
    cursor: pointer;
}

.smallModal {
    display: block;
    padding-top: 15rem;
}

.hide-input-arrows {
    -moz-appearance: textfield;
}

#dataTypeSwitch:checked ~ .custom-control-label::before {
    color: #fff;
    border-color: #165C7D;
    background-color: #165C7D;
}

.hide-input-arrows::-webkit-outer-spin-button, .hide-input-arrows::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.css-26l3qy-menu {
    z-index: 5 !important;
}

.defaultBorder {
    border: 1px solid transparent;
    border-left: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
}

.popover{
    max-width: unset !important;
}

.defaultBorder.lastRow{
    border-bottom: unset;
}

.inventorySelectedCollum.start {
    border: 1px solid #ddeeff !important;
    background-color: #ddeeff !important;
}

.inventorySelectedRow {
    border-left: 1px solid #dee2e6;
    border-bottom: 1px solid #dee2e6;
    /* border-top: 1px solid #56b0ff;
    border-bottom: 1px solid #56b0ff; */
}

.inventorySelectedCollum.inventorySelectedRow{
    border-top: 1px solid #56b0ff;
    border-bottom: 1px solid #56b0ff;
    border-left: 1px solid #56b0ff;
    border-right: 1px solid #56b0ff;
}

.inventorySelectedCollum.inventorySelectedRow.collum0{
    border-left: 1px solid #56b0ff;
}

.inventorySelectedCollum.inventorySelectedRow.row0{
    border-top: 1px solid #56b0ff;
}

/* Chrome, Safari, Edge, Opera */
.hiddenNumberArrows::-webkit-outer-spin-button,
.hiddenNumberArrows::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
.hiddenNumberArrows[type=number] {
  -moz-appearance: textfield;
}

/*#region Tooltip for logs */
.customTooltip {
    position: absolute;
}

.customTooltip .tooltipText {
    visibility: hidden;
    position: absolute;
    z-index: 2;
    color: white;
    font-size: 12px;
    background-color: #192733;
    border-radius: 10px;
    padding: 5px 10px;
    right: -10px;
    top: 140%;
}

.tooltipText::before {
    content: "";
    position: absolute;
    transform: rotate(45deg);
    background-color: #192733;
    padding: 5px;
    z-index: 1;
    top: -4px;
    right: 10px;
}

.customTooltip:hover .tooltipText {
    visibility: visible;
    opacity: 1;
}

/*#endregion */


/*STRIPO*/

.stripoFolder{
    border: 2px black solid;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    cursor: pointer;
    margin: 0.5rem;
    vertical-align: middle;
}

.stripoFolderTitle{
    white-space: nowrap;
    overflow: hidden;
    max-width: 250px;
    text-overflow: ellipsis;
}

.stripoUnsavedChanges{
    width: 10px;
    height: 10px;
    border-radius: 100px;
    background-color: #cbe5ff;
    position: absolute;
    margin-top: -8px;
    margin-right: -8px;
}

#stripoSettingsContainer li{
    width: unset;
}

image-library-section .btn-group.pull-right{
    margin-top: unset !important;
    margin-right: unset !important;
}

image-library-section input.form-control.input-sm.upload-link{
    border-radius: var(--border-radius-base) !important;
}

image-library-section .input-group-btn.image-actions{
    padding-left: 15px;
}

image-library-section buttons-selector div.pull-right{
    margin-top: unset !important;
}

.sripo-watermark.center-block{
    display: none;
}

.complexPhotos .image-gallery-thumbnails {
    overflow: auto;
    width: 105% !important;
}

/* BE CONFIG */
.unSelectedChat {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0.7rem;
    background: #BFBFBF;
    border-radius: 100px;
    font-size: 1.5em;
    color: white;
    margin-inline: 0.5rem;
    max-width: 40px;
    max-height: 40px;
    min-width: 40px;
    min-height: 40px;
}

.Facebook-Messenger{
    background: #0084ff;
}

.Instagram{
    background: #f09433; 
    background: -moz-linear-gradient(45deg, #f09433 0%, #e6683c 25%, #dc2743 50%, #cc2366 75%, #bc1888 100%); 
    background: -webkit-linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    background: linear-gradient(45deg, #f09433 0%,#e6683c 25%,#dc2743 50%,#cc2366 75%,#bc1888 100%); 
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f09433', endColorstr='#bc1888',GradientType=1 );
}

.Whatsapp{
    background: #4dc247;
}

.Skype{
    background: #00aff0;
}

*::-webkit-scrollbar-track{
	border-radius: 10px;
	background-color: #F5F5F5;
}

*::-webkit-scrollbar{
	width: 12px;
	background-color: #F5F5F5;
    height: 10px;
}

*::-webkit-scrollbar-thumb{
	border-radius: 10px;
	background-color: #d9d9d9;
}

.hasAmenitiesSelected {
    width: 10px;
    height: 10px;
    border-radius: 100px;
    background-color: var(--MainColor);
    position: absolute;
    margin-top: -45px;
    margin-right: -45px;
}

#MicroInvetoryUpdate .IventoryDataScroll::-webkit-scrollbar{
    display: none !important;
}
  
#MicroInvetoryUpdate .IventoryDataScroll{
    -ms-overflow-style: none !important;
    scrollbar-width: none;
}

.DayPickerKeyboardShortcuts_buttonReset.DayPickerKeyboardShortcuts_buttonReset_1.DayPickerKeyboardShortcuts_show.DayPickerKeyboardShortcuts_show_2.DayPickerKeyboardShortcuts_show__bottomRight.DayPickerKeyboardShortcuts_show__bottomRight_3{
    display: none;
}

.CalendarDay__hovered_span.CalendarDay__default, .CalendarDay__hovered_span.CalendarDay__default:hover {
    background: #fff;
    border: 1px solid #e4e7e7;
    color: #484848;
}

.newVersion {
    cursor: pointer;
    font-size: 0.9em;
}

.newVersion:hover {
    color: #007bff;
    text-decoration: underline;
}

.tutorialClose {
    position: absolute;
    background: white;
    width: 30px;
    top: -15px;
    right: -12px;
    border-radius: 100%;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.tutorial .popover {
    box-shadow: 0px 3px 15px #6F73881A !important;
    border: 0;
}

.white-bg .tooltip-inner {
    background-color: #fff !important;
    border: 1px solid #2d2d2d !important;
    color: black !important;
}

.white-bg-top, .white-bg-bottom{
    padding: 0.4rem 0;
}

.white-bg-right, .white-bg-left{
    padding: 0 0.4rem;
}

.noHoverNavItem:hover{
    border-bottom: 2px solid #ffffff !important;
}

.navItemHeyTravelAi{
    margin-bottom: -3px !important;
}

.fromRate label::before{
    background-color: #007bff96 !important;
    border-color: #007bff2e !important;
}

.copiableCell {
    position: relative;
}

.copiableCell i {
    visibility: hidden;
    position: absolute;
    bottom: 0;
    right: 0;
    color: grey;
}

.copiableCell:hover i {
    visibility: visible;
}

.excludePriceComponent {
    width: 19px;
    height: 38px;
    border-bottom: 2px solid black;
    -webkit-transform: translateY(-26px) translateX(-5px) rotate(40deg);
    position: absolute;
}

.table-hover tbody tr:has(.no-hover-bg):hover td, .table-hover tbody tr:has(.no-hover-bg):hover {
    background-color: transparent !important;
}

.table-responsive .table {
    margin-bottom: 0.5rem;
}

.table-responsive {
    margin-bottom: 1rem;
}

.react-bootstrap-table-pagination-list-hidden {
    display: none;
}

#MicroMassCalendar .DateRangePicker_calendarIcon.DateRangePicker_calendarIcon_1,
#MicroMassCalendar .DateInput, #MicroMassCalendar .DateRangePickerInput_arrow.DateRangePickerInput_arrow_1,
#MicroMassCalendar .DateRangePicker_arrow.DateRangePickerInput_arrow_1{
    display: none !important;
}

#MicroMassCalendar .DateRangePicker_picker{
    z-index: 99 !important;
    top: 0 !important;
    width: 100% !important;
    position: relative !important;
}

#MicroMassCalendar .DayPicker__withBorder{
    box-shadow: unset !important;
}

#MicroMassCalendar .DateRangePickerInput__withBorder{
    border: 0 !important;
}

#MicroMassCalendar .CalendarDay__selected {
    background: #8bc3ff !important;
    color: white !important;
}

#MicroMassCalendar .CalendarDay__blocked_calendar, #MicroMassCalendar .CalendarDay__blocked_calendar:active, #MicroMassCalendar .CalendarDay__blocked_calendar:hover{
    background: #007bff !important;
    color: white !important;
}

#MicroMassCalendar .DayPickerNavigation_button__horizontalDefault{
    display: none;
}

#MicroMassCalendar.MobileCalendarBulk .DateRangePicker_picker {
    top: 52px !important;
}

#MicroMassCalendar.MobileCalendarBulk .DateRangePicker_picker{
    width: 100% !important;
    position: unset !important;
    top: unset !important;
    z-index: unset !important;
}

#MicroMassCalendar.MobileCalendarBulk .DateRangePickerInput__withBorder{
    height: unset !important;
}

.title-sm {
    color: #6F7388;
    font-size: 0.8em;
    margin-bottom: 0.25rem;
}

.faqHover:hover {
    background-color: #f6f6f6f0;
}

.collapsing{
    width: 0;
    height: 100%;
    transition: width .35s ease;
}

#EmailDetail .dropdown button {
    box-shadow: none !important;
}

#EmailDetail .dropdown-item:hover{
    border-left: none;
}

.iframeInvisibleDiv {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999; 
    pointer-events: auto;
    opacity: 0;
}

.iframeNotSelectable *{
    user-select: none;
}