
html {
    font-size: 14px;
    
}

html, body {
    height: 100%;
    color: #3a3a3a;
}

.modal-small {
    min-width: 600px !important
}

.modal-medium {
    min-width: 850px !important
}

@media (min-width: 768px) {
    html {
        font-size: 16px;
    }
}

@media (min-width: 1200px) {
    .modal-lg {
        width: 1170px;
        min-width: 100%;
    }
    .modal-dialog {
        width: 1170px;
        min-width: 80%;
    }
}
.pointer {
    cursor: pointer;
}
.rowGrid {
    min-height: 33.5px;
}

.modal-header {
    background-color: black;
    color: #fff;
}

.close {
    color: #fff;
    opacity: 1;
}

.close:hover {
    color: #fff;
    opacity: 1;
}

    .close:visited {
        color: #fff;
        opacity: 1;
    }


.tab-pane {
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    border-radius: 0px 0px 5px 5px;
    padding: 10px;
}


.pagination > .active > a, .pagination > .active > a:hover, .page-item.active .page-link {
    background-color: #165C7D;
    border-color: #165C7D;
}

.pagination > li > a {
    color: #545b62
}

.card-header {
    padding: 0.4rem 1.25rem;
    font-size: 1rem;
    background-color: #fff;
}

.customnav-item {
    /*border-bottom: 2px solid transparent;
    */
}

    .customnav-item:hover, .customnav-item:active, .show {
        /* border-bottom: 2px solid #EDC934;*/
    }

.nav-tabs .nav-link {
    margin-bottom: 0px;
    border-color: #dee2e6 #dee2e6 #dee2e6;
}

.text-host {
    color: #165C7D !important;
}
.tab-border-host {
    border-radius: 0px !important;
    border-bottom: 3px solid #165C7D !important;
}
.btn-host {
    color: #ffffff;
    background-color: #165C7D;
    border-color: #165C7D;
}
.btn-delete{
    background: #A80C19;
    border-color: transparent;
}
.border-host {
    border-color: #165C7D;
}
.color-host {
    color: #165C7D;
}
.bg-host {
    background-color: #165C7D;
}
.bg-green {
    background-color: #58D68D;
}
.bg-light-green {
    background-color: #BEDB39;
}
.bg-dark-green {
    background-color: #33691E;
}
.bg-light-blue {
    background-color: #B1FF91;
}
.bg-blue {
    background-color: #00ABD8;
}
.bg-dark-orange {
    background-color: #F4511E;
}
.bg-orange {
    background-color: #FF5722;
}
.bg-light-dark {
    background-color: #165C7D11 /*#f0f0f0*/
}
.bg-disabled {
    background-color: #f0f0f0 
}
.bg-soft-blue {
    background-color: #eefaff
}
.bg-soft-airbnb {
    background-color: #ff5a5f1a /*ffb5b85e*/
}
.lb-sm {
    width: 60px;
}

.lb-md {
    width: 120px;
}

.shadow {
    box-shadow: 0px 3px 15px #6F73881A !important;
}

.deep-shadow {
    box-shadow: 0px 3px 15px #0000001a !important;
}

* {
    box-sizing: border-box;
}


.form-margim {
    margin: 1rem;
}

.form-control {
    min-height: 38px;
    height: calc(1.80rem + 2px);
}

.form-control {
    padding: 2px 8px;
}

.wrapper {
    display: grid;
    grid-template-columns: repeat(3, 2fr);
    grid-row-gap: 1em;
    grid-column-gap: 1em;
}

.wrapper > div {
    padding: 4px;
    padding-bottom: 0;
    padding-top: 0;
}

.border-left-primary {
    border-left: .25rem solid #165C7D !important;
}

.border-left-white {
    border-left: .25rem solid #ffffff !important;
}

.border-left-danger {
    border-left: .25rem solid #dc3545 !important;
}

.noborder {
    border: 0;
}

.cleanInput {
    border: 0;
    box-shadow: none;
    outline: 0 none;
    background: transparent;
}

.card-body-gridview {
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0rem 0.9rem;
}

.card-header-gridview {
    padding: 0.1rem 1.0rem;
}

.card-header-form {
    padding: 0.3rem 1.25rem;
    border-bottom-color: #ffc107;
    border-bottom-width: 3px;
}

.container {
    max-height: 100%;
    max-width: 100%;
}

.modal.fade {
    z-index: 10000000 !important;
}

.font_size_xxxs {
    font-size: 8px
}

.font_size_xxs {
    font-size: 14px
}

.max-with-14Col {
    max-width: 5.35%;
}

#navmenubar {
    padding-bottom: 0px !important;
    padding-top: 0px !important;
}

.navbar-expand-lg .navbar-nav {
    flex-wrap: wrap;
    justify-content: center;
}

.navbar-brand {
    padding-bottom: 0px !important;
}


.bg-light {
    background-color: #FAFCFF !important;
}

.input-group-sm > .form-control, .input-group-sm > .input-group-append > .btn, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-prepend > .input-group-text {
    height: calc(1.8125rem + 4px);
}

    .input-group-sm > .form-control:not(textarea), .input-group-sm > .custom-select {
        height: calc(1.5em + 0.5rem + 4px);
    }

.input-group-text {
    background-color: #fff;
    min-height: 38px;
}
.input-group-sm > .form-control, .input-group-sm > .custom-select, .input-group-sm > .input-group-prepend > .input-group-text, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-append > .btn {
    padding: 2px 8px;
}

.css-yk16xz-control {
    border: 2px solid #165C7D11 !important;
    border-radius: 5px !important;
}

input[type=text], input[type=email], input[type=number], input[type=password], textarea.form-control {
    border: 2px solid #165C7D11;
    border-radius: 5px !important;
}

input[type="checkbox"] + *::before, input[type="radio"] + *::before {
    border: 2px solid #165C7D11;
    border-radius: 5px !important;
}

input[type=text]:focus, input[type=email]:focus, input[type=number]:focus, input[type=password]:focus, textarea.form-control:focus {
    border: 2px solid #165C7D;
}

.SingleDatePickerInput__withBorder {
    border: 2px solid #165C7D11 !important;
    border-radius: 5px !important;
}

.input-group-text {
    border: 2px solid #165C7D11;
    border-radius: 5px 0px 0px 5px;
}

.reservationGuests .SingleDatePicker, .reservationGuests .SingleDatePickerInput {
    width: 100% !important;
    text-align: left;
}

/* skin.css Style*/
.upload-drop-zone {
    color: #ccc;
    border-style: dashed;
    border-color: #ccc;
    text-align: center
}

    .upload-drop-zone.drop {
        color: #222;
        border-color: #222;
    }

.badge-notify {
    background-color: #165C7D;
    position: absolute;
    top: 3px;
    left: 29px;
}


.mailboxballon {
    white-space: pre-line;
    text-align: initial;
    word-break: break-word;
    font-size: 15px;
}

.mailBoxToReply {
    background-color: #049fe7;
}


.nav-item:hover {
    border-bottom: 2px solid rgb(22, 92, 125);
}
.nav-item {
    border-bottom: 2px solid #ffffff;
}
.customnav-item:hover{
    border-bottom: 2px solid transparent;
}
.dropdown-item:hover {
    border-left: 2px solid rgb(22, 92, 125);
}

.overbglight:hover {
    background-color: #f8f9fa !important;
}

.bg-host-hover:hover {
    background-color: #165C7D66 !important;
    border-radius: 10px;
}

.bg-host-hover-delete:hover {
    background-color: #f4726f !important;
    border-radius: 10px;
}

.show-on-hover {
    display: none;
}

.bg-host-hover:hover .show-on-hover, .bg-host-hover-delete:hover .show-on-hover {
    display: initial;
}

.steps {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    overflow-x: auto;
}
.steps .step:first-child {
  margin-left: auto;
}
.steps .step:last-child {
  margin-right: auto;
}

.step:first-of-type .step-circle::before {
  display: none;
}
.step:last-of-type .step-content {
  padding-right: 0;
}

.step-content {
  box-sizing: content-box;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 8rem;
  min-width: 8rem;
  max-width: 8rem;
  padding-top: 0.5rem;
  padding-right: 1rem;
}

.step-circle {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 1.5rem;
    height: 1.5rem;
    color: #165C7D;
    border: 2px solid #adb5bd;
    border-radius: 100%;
    background-color: #fff;
}
.step-circle::before {
  content: "";
  display: block;
  position: absolute;
  top: 50%;
  left: -2px;
  width: calc(8rem + 1rem - 1.5rem);
  height: 2px;
  transform: translate(-100%, -50%);
  color: #adb5bd;
  background-color: currentColor;
}

.step-text {
  color: #adb5bd;
  word-break: break-all;
  margin-top: 0.25em;
  margin-bottom: 0.1rem;
}

.step .step-circle {
    color: #fff;
    background-color: #165C7D;
    border-color: #165C7D;
}
    .step .step-circle::before {
        color: #165C7D;
    }
.step .step-text {
    color: #165C7D;
}

.step-error .step-circle {
    color: #fff;
    background-color: #165C7D;
    border-color: #165C7D;
}
    .step-error .step-circle::before {
        color: #165C7D;
    }
.step-error .step-text {
    color: #165C7D;
}

.step-active .step-circle {
    color: #165C7D;
    background-color: #fff;
    border-color: #165C7D;
}
    .step-active .step-circle::before {
        color: #165C7D;
    }
.step-active .step-text {
    color: #165C7D;
    font-weight: 500;
}

#whitelistemail{
    overflow: auto;
    max-height: 60px;
    flex-wrap: wrap;
}

.closeModal {
    color: grey;
    position: absolute;
    background-color: #FAFCFF;
    border-radius: 20px;
    padding: 4px 6px;
    width: 35px;
    height: 35px;
    top: -12px;
    right: -10px;
    font-size: 18px;
    z-index: 999;
    border: 0;
}

    .closeModal:hover {
        background-color: #6c757d;
        color: white;
    }

@media (max-width: 1500px) {
    .roomNightsByCountry {
        max-height: 210px;
        overflow: auto;
    }
    .roomNightsByCountry {
        max-height: 210px;
        overflow: auto;
    }
}

@media (max-width: 1501px) {
    .AnalyticsBox{
        margin-top: 0.5rem;
    }
    .roomNightsByCountry {
        max-height: 190px;
        overflow: auto;
    }
}


@media (min-width: 1501px) {
    .AnalyticsBox{
        display: inline-block;
        max-width: 50%;
    }
    .roomNightsByCountry {
        max-height: 310px;
        overflow: auto;
        padding-top: 2rem;
    }
}


.beListByCountry {
    max-height: 310px;
    overflow-y: auto;
    overflow-x: hidden;
}

.table-hover tbody tr:hover td {
    background-color: #ededed !important;
}


.scrollableDiv::-webkit-scrollbar {
    height: 10px;
    width: 5px;
}

.scrollableDiv::-webkit-scrollbar-track {
    background: rgb(241, 241, 241);
    border-radius: 10px;
}

.scrollableDiv::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgb(193, 193, 193);
}

#overflowAiPrompt {
    overflow: auto;
    max-height: 60px;
    flex-wrap: wrap;
}


/*react-draft-wysiwyg*/
.public-DraftStyleDefault-ltr {
    text-align: inherit !important;
}

.btn-inventory {
    background-color: #ffffff;
    color: #165C7D;
    border-color: #165C7D;
}

.btn-inventory:disabled {
    color: #ffffff;
    background-color: #165C7D;
    border-color: #165C7D;
}

/* .chartWrapper {
    position: relative;
    overflow-x: scroll;
}

.chartWrapper > canvas {
    position: absolute;
    left: 0;
    top: 0;
    pointer-events: none;
}

.chartAreaWrapper {
    width: 1200px;
    height: 450px;
} */



.scrollableDiv::-webkit-scrollbar {
    height: 10px;
    width: 5px;
}

.scrollableDiv::-webkit-scrollbar-track {
    background: rgb(241, 241, 241);
    border-radius: 10px;
}

.scrollableDiv::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgb(193, 193, 193);
}

.scrollableDiv {
    width: 100%;
    height: 100%;
    overflow-x: auto;
    white-space: nowrap;
    display: inline-block;
    overflow-y: hidden;
}

.verticalScroll::-webkit-scrollbar-track {
    background: rgb(241, 241, 241);
    border-radius: 10px;
}

.verticalScroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgb(193, 193, 193);
}

.verticalScroll::-webkit-scrollbar {
    height: 1px;
    width: 4px;
}

.currency {
    left: -60px;
}
.hide-input-arrows {
    -moz-appearance: textfield;
}

.hide-input-arrows::-webkit-outer-spin-button, .hide-input-arrows::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

.languageStyles{
    position: unset !important;
    transform: none !important;
    margin: 20px !important;
}